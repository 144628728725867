$(function () {

  $("#login-button").on("click", function(eo){
    const windowFeatures = "width=1,height=1";
    let logout_url = $("#sessions_new_form_ac_logout_url").val();
    let newwin = window.open(logout_url, "ac_logout",windowFeatures);
    let parent_form = $(this).parents('form')
    setTimeout(function(){
      newwin.close()
      parent_form.attr('action', "/sessions/create");
      parent_form.attr('method', "post");
      parent_form.submit();
    },1000);
    return false;
  });

  $(".link").on("click", function(eo){
    eo.preventDefault();
    eo.attr('action', $(this).data('action'));
    eo.attr('method', $(this).data('method'));
    eo.on("submit");
  });
});
